<template>
  <el-menu 	id="pg_nav" 
						:default-active="pg"  
						@select="handleSelect" 
						background-color=inherit
            text-color="#FFFFFF"
            active-text-color="#454545"
	>

    <el-menu-item index="scsj">实时数据</el-menu-item>
    <el-menu-item index="table">历史数据</el-menu-item>
	</el-menu>
</template>

<style scoped>

</style>

<script>
// @ is an alias to /src
//import { Menu,MenuItem } from  'element-ui'


export default {
        name: 'pg_nav',
        components: {
          //[Menu.name]:Menu,
          //[MenuItem.name]:MenuItem,

        },

        data() {
          return {

          }
        },

methods: {
           handleSelect(key, keyPath) {
            //console.log("sc_nav:" , key);
             if (key == 'scsj'){

                 let lc = this.$route.params.lc;
               if (!lc){return}
              let domain = document.domain.split('.').slice(-2).join('.')
               //let domain_port = window.location.host;
               //let maohao = domain_port.indexOf(":");
               //let domain = "";
               //if (maohao > 0) {
               //  domain = domain_port.substr(0, maohao);
               //} else {
                // domain = domain_port;
               //}
              window.location.replace("https://" + key + "." + domain + "/#/scsj/" + lc )
              //   window.location = "http://" + domain + ":82/#/scsj/" + lc
    

            }else{
            //let fullPath = this.$route.fullPath
            //fullPath = fullPath.split("/")
            //if (fullPath[1]){
            //fullPath[2] = key
            //fullPath = fullPath.join("/")
            //this.$router.replace(fullPath)
            }
         }
},
computed:{
           pg(){
                let fullPath = this.$route.fullPath
                fullPath = fullPath.split("/")
                return fullPath[2]
                    
           }

         }

}
</script>
