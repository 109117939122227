<template>
<div id="home">
<el-container id ="h_main" >
        <sc_nav id="sc_nav" @tgAside="tgAside"></sc_nav>
  <el-container id="c_main">
    <el-aside width="100px"
      :class="{'hide_aside':hide_aside}"
    >
       <pg_nav></pg_nav>
    </el-aside>
    <el-main>
    <keep-alive>
        <router-view :key="key"></router-view>
    </keep-alive>
    </el-main>
  </el-container>
  <el-footer :ref="tip" style="height:45px"><div>{{tip}}</div></el-footer>
</el-container>
</div>
</template>


<style scoped>
  #home,
  #h_main{
    width:100%;
    height:100%;
    margin:0;
  } 

  #c_main{
    width:100%;
    height:50%;
    margin:0;
    margin-bottom: 30px;
  }

  .el-header {
    display: flex;
    background-color: #63B8FF;
    color: #333;
    text-align: center;
    width:100%;
		align-items:center;
  }

  .el-footer{
    display: flex;
    background-color: #63B8FF;
    color: #333;
    text-align: center;
    height:10px;    
    width:100%;
    position:absolute;
    bottom:0px;
    left:0px;
		align-items:center;
    z-index:999;

  }
  
  .el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
  }
  
  .el-main {
    background-color: #E9EEF3;
    color: #333;
    text-align: center;
    bottom:0px;
    padding-bottom:60px;
  }
.hide_aside{
display:none;

}

@media screen and (max-width: 800px) {
  .el-main{

    padding:0px ;
    padding-bottom:60px;
  }

  .el-footer{
    display:none;
  }

  .el-aside{
position: fixed;
height:100%;
z-index:9;
  }

}
</style>

<script>
// @ is an alias to /src
//import { Container,Header,Aside,Main,Footer} from  'element-ui'

import sc_nav  from '@/components/sc_nav.vue'
import pg_nav  from '@/components/pg_nav.vue'

export default {
  name: 'Home',
  components: {
			//[Container.name]:Container,
			//[Header.name]:Header,
			//[Aside.name]:Aside,
			//[Main.name]:Main,
			//[Footer.name]:Footer,
      sc_nav,
      pg_nav,
  },

	data() {
      return {
        hide_aside:false,
        tip:""
      }
    },
    computed: {
      // 默认情况下不同路由引入同一个组件 不触发created 数据作用域是同一个，加一个可以加以区别。
      key() {
        //console.log("key::",this.$route.fullPath)
        return this.$route.fullPath
        //return this.$route.name !== undefined ? this.$route.name : this.$route
      }
    },
methods:{
          tgAside(){
            //console.log('tgAside')
            this.hide_aside = !this.hide_aside    
          }

        },
        created(){
            if (this.$deviceType == 'pc')
            this.hide_aside = false
            else
            this.hide_aside = true
        
        }
}
</script>
