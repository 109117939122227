import Vue from 'vue'
import VueRouter from 'vue-router'
//import chart from '../views/chart.vue'

Vue.use(VueRouter)


const routes = [
  {
    path: '/:lc/table',
    component: () => import('../views/table.vue'),
    meta: {
            title: '历史数据'
    },
    //component: table
  },
  //{
    //path: '/:lc/chart',
    //component: chart
  //},
  //{
    //path: '/about',
    //name: 'About',
    //// route level code-splitting
    //// this generates a separate chunk (about.[hash].js) for this route
    //// which is lazy-loaded when the route is visited.
    //component: () => import([> webpackChunkName: "about" <] '../views/About.vue')
  //}
]

const router = new VueRouter({
  routes
})

export default router
