<template>
  <div id="sc_nav">
    <van-nav-bar
      :title="title"
      left-text="主页"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    >
      <template #right>
        <van-icon name="wap-nav" size="18" />
      </template>
    </van-nav-bar>
  </div>
</template>

<style scoped>
#sc_nav >>> * {
color:#FFFFFF;

}

#sc_nav >>> .van-nav-bar{
background-color: #63b8ff;
}

</style>

<script>
// @ is an alias to /src
//import { Menu, MenuItem } from "element-ui";
//import { NavBar } from "vant";
//import { Icon  } from 'vant'
//import "vant/lib/nav-bar/style";

export default {
  name: "sc_nav",
  components: {
    //[Menu.name]: Menu,
    //[MenuItem.name]: MenuItem,
    //[NavBar.name]: NavBar,
    //[Icon.name]: Icon,
  },

  data() {
    return {
      lc: "",
    };
  },

  methods: {
    handleSelect(key, keyPath) {
      //console.log("sc_nav:" , key);
      let fullPath = this.$route.fullPath;
      fullPath = fullPath.split("/");
      fullPath[1] = key;
      fullPath = fullPath.join("/");
      this.$router.replace(fullPath);
    },
    onClickLeft(){
      //let domain_port = window.location.host;
      //let maohao = domain_port.indexOf(":");
      //let domain = "";
      //if (maohao > 0) {
      //  domain = domain_port.substr(0, maohao);
      //} else {
      //  domain = domain_port;
      //}
      let domain = document.domain.split('.').slice(-2).join('.')
        window.location.replace("https://www." + domain)

    },
    onClickRight(){
        this.$emit('tgAside') 
    }
  },
  computed: {
    title() {
      if ((this.$route.params.lc == "jbsc")) {
        return "江北水厂";
      } else if ((this.$route.params.lc == "thsc")) {
        return "潼湖水厂";
      } else if ((this.$route.params.lc == "qdsc")) {
        return "桥东水厂";
      } else if ((this.$route.params.lc == "hnasc")) {
        return "河南岸水厂";
      }else{
        return ""
      }
    },
  },
};
</script>
