
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
//import ElementUI from 'element-ui';
//import {Loading} from  'element-ui'

//import Vant from 'vant';

//Vue.use(ElementUI);
//Vue.use(Loading.directive);
//Vue.use(Vant);
//Vue.prototype.$loading = Loading.service;


if (_isMobile()) {
	Vue.prototype.$deviceType = 'mobile'
    //console.log(window);
    //console.log(document);
    //const s = document.createElement('script');
    //s.type = 'text/JavaScript';
    //s.src = 'https://cdn.jsdelivr.net/npm/vant@2.10.14/lib/vant.min.js';
    //document.head.appendChild(s);
} else {
	Vue.prototype.$deviceType = 'pc'
}

// [
//   "component", {
//     "libraryName": "element-ui",
//     "styleLibraryName": "theme-chalk"
//   }

// ],

function _isMobile() {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag;
}

Vue.config.productionTip = false


router.beforeEach((to, from, next) => {
  // 路由发生变化修改页面title
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

axios.interceptors.response.use(function (response) {
  return response;
},function (error) {
  if (error.response.status === 401) {
		console.log("err401")
    window.location = error.response.data
}
}
)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
