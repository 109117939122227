<template>
  <div id="app">
    <Home />
  </div>
</template>


<script>
import Home from "@/views/Home.vue";

export default {
  name: "app",
  components: {
    Home,
  },
  data() {
    return {};
  },
  created() {},
};
</script>

<style>
body,
#app,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  position: relative;
  min-height: 100%;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
